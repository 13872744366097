export const EXPERIMENTS = {
  ADDITIONAL_BUTTON_SETTINGS_VIEWER: 'specs.membership.AdditionalButtonSettingsViewer',
  ADDITIONAL_BUTTON_SETTINGS_EDITOR: 'specs.membership.AdditionalButtonSettingsEditor',
  DYNAMIC_TAGLINE: 'specs.membership.PricingPlansTpaDynamicTagline',
  NEW_ADDONS_DESIGN: 'specs.membership.NewAddonsDesign',
  ENABLE_COUPONS: 'specs.membership.EnableCoupons',
  SINGLE_PLAN_ADDON: 'specs.membership.SinglePlanAddon',
  FIT_TO_CONTENT_HEIGHT: 'specs.membership.FitToContentHeight',
  SHOW_ADD_COUPON_LABEL: 'specs.membership.ShowAddCouponLabel',
};
